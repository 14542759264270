import React from "react";
import { createRoot } from "react-dom/client";
import storageService from "./services/storage";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Provider } from "urql";
import client from "./services/api";
import "./teleporthq/style.css";
import Login from "./teleporthq/pages/login";
import Business from "./teleporthq/pages/business";
import Settings from "./teleporthq/pages/settings";
import NewDepartment from "./teleporthq/pages/new_department";
import TeamEditor from "./teleporthq/pages/team_editor";
import Info from "./teleporthq/pages/info";
import Step001 from "./teleporthq/pages/step001";
import Step010 from "./teleporthq/pages/step010";
import Step020 from "./teleporthq/pages/step020";
import Step030 from "./teleporthq/pages/step030";
import Success from "./teleporthq/pages/success";
import Error from "./teleporthq/pages/error";
import Wallet from "./teleporthq/pages/wallet";
import Scanforbalance from "./teleporthq/pages/scanforbalance";

const isAuthenticated = () => {
  const token = storageService.getStorageData("access_token");
  if (token == null) {
    return false;
  } else {
    return true;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (isAuthenticated() === true ? <Component {...props} /> : <Redirect to='/login' />)} />
);

const App = () => {
  return (
    <Provider value={client}>
      <Router>
        <PrivateRoute component={Business} exact path='/' />
        <PrivateRoute component={Settings} exact path='/settings/:dep_uid' />
        <PrivateRoute component={NewDepartment} exact path='/new_department' />
        <PrivateRoute component={TeamEditor} exact path='/team' />
        <PrivateRoute component={Info} exact path='/info' />
        <PrivateRoute component={Success} exact path='/success' />
        <PrivateRoute component={Error} exact path='/failed' />
        <PrivateRoute component={Wallet} exact path='/wallet' />
        <PrivateRoute component={Scanforbalance} exact path='/scanforbalance' />

        <PrivateRoute component={Step001} exact path='/stage001/:contract_id' />
        <PrivateRoute component={Step010} exact path='/stage010/:contract_id' />
        <PrivateRoute component={Step020} exact path='/stage020/:contract_id' />
        <PrivateRoute component={Step030} exact path='/stage030/:contract_id' />

        <Route component={Login} exact path='/login' />
      </Router>
    </Provider>
  );
};

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab='home' />);

//ReactDOM.render(<App />, document.getElementById("app"));
