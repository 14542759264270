import React, { useState } from "react";
import Avatar from "react-avatar";

import PropTypes from "prop-types";

import "./avatarlistitemwithdelete.css";

const Avatarlistitemwithdelete = (props) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const confirm_delete = () => {
    setDeleteConfirmation(true);
  };

  const actual_delete = () => {
    props.mutation({ pos_id: props.pos_id });
    props.rexecute();
    window.location.reload();
  };

  let avatar_img = "";
  try {
    if (props.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
    }
  } catch {}
  return (
    <div className={`avatarlistitemwithdelete-applicationitem ${props.rootClassName} `}>
      <Avatar src={avatar_img} size='50' name={props.name} className='avatarlistitemwithdelete-image' />
      <span className='avatarlistitemwithdelete-text'>{props.name}</span>
      {deleteConfirmation ? (
        <button className='avatarlistitemwithdelete-button button' onClick={actual_delete}>
          Confirm
        </button>
      ) : (
        <svg viewBox='0 0 1024 1024' className='avatarlistitemwithdelete-icon' onClick={confirm_delete}>
          <path
            d='M810 170v86h-596v-86h148l44-42h212l44 42h148zM256 810v-512h512v512q0 34-26 60t-60 26h-340q-34 0-60-26t-26-60z'
            className=''></path>
        </svg>
      )}
    </div>
  );
};

Avatarlistitemwithdelete.defaultProps = {
  user_name: "User Name",
  rootClassName: "",
  avatar: "/2021_0711_00131000-01-02-01.jpeg",
};

Avatarlistitemwithdelete.propTypes = {
  user_name: PropTypes.string,
  rootClassName: PropTypes.string,
  avatar: PropTypes.string,
};

export default Avatarlistitemwithdelete;
