import React from "react";
import Avatar from "react-avatar";

import PropTypes from "prop-types";
import uuid from "react-uuid";
import "./avatarlistitemwithadder.css";
import storageService from "../../services/storage";

const Avatarlistitemwithadder = (props) => {
  let avatar_img = "";
  try {
    if (props.avatar.path == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.avatar.path;
    }
  } catch {}

  const execute = () => {
    let working_dep = storageService.getStorageData("working_dep");
    if (props.category == "Team") {
      props.mutation({ pos_id: uuid(), profile_id: props.profile_id, level: "TEAM", title: "Employee", dep_id: working_dep });
    } else {
      props.mutation({ pos_id: uuid(), profile_id: props.profile_id, level: "MANAGER", title: "Manager", dep_id: working_dep });
    }
    props.reexecute();
    window.location.reload();
  };

  return (
    <div className={`avatarlistitemwithadder-applicationitem ${props.rootClassName} `}>
      <Avatar src={avatar_img} size='50' name={props.name} className='avatarlistitemwithadder-image' />

      <span className='avatarlistitemwithadder-text'>{props.name}</span>
      <svg viewBox='0 0 1024 1024' className='avatarlistitemwithadder-icon' onClick={execute}>
        <path d='M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z' className=''></path>
      </svg>
    </div>
  );
};

Avatarlistitemwithadder.defaultProps = {};

Avatarlistitemwithadder.propTypes = {};

export default Avatarlistitemwithadder;
