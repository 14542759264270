import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "react-avatar";

import PropTypes from "prop-types";
import Profile from "./profile";
import BusinessSelector from "./business_selector";
import uuid from "react-uuid";
import { useHistory } from "react-router-dom";

import "./header.css";
import storageService from "../../services/storage";

const Header = (props) => {
  let history = useHistory();

  const [visibility, setVisibility] = useState(false);

  const [biz_visibility, setBizVisibility] = useState(false);

  async function swicthBizProfile() {
    if (biz_visibility == true) {
      setBizVisibility(false);
    } else {
      setBizVisibility(true);
    }
  }

  async function swicthProfile() {
    if (visibility == true) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  }

  const all_profiles = JSON.parse(storageService.getStorageData("profiles"));
  const active_profile = storageService.getStorageData("active_profile");

  const working_dep = storageService.getStorageData("working_dep");
  const app_deps = JSON.parse(storageService.getStorageData("app_deps"));

  let have_dep = false;
  let working_dep_name = "";
  if (working_dep) {
    have_dep = true;
    app_deps.map((dep) => {
      if (dep.id == working_dep) {
        working_dep_name = dep.name;
      }
    });
  }
  let homepage = false;
  const location = useLocation();
  if (location.pathname == "/") {
    homepage = true;
  } else {
    working_dep_name = "<- " + working_dep_name;
  }

  let profile_data = {};

  all_profiles.forEach((element) => {
    if (element["id"] === active_profile) {
      profile_data = element;
    }
    if (element["uid"] === active_profile) {
      profile_data = element;
    }
  });

  let avatar_img = "";
  try {
    if (profile_data["avatar"]["path"] == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + profile_data["avatar"]["path"];
    }
  } catch {}

  async function add_new() {
    history.push("/new_department");
  }

  return (
    <div className={`header-header ${props.rootClassName} `}>
      {have_dep && (
        <div className='lost_box'>
          {homepage && (
            <svg viewBox='0 0 1024 1024' className='header-icon' onClick={swicthBizProfile}>
              <path d='M316 366l196 196 196-196 60 60-256 256-256-256z' className=''></path>
            </svg>
          )}
          <Link to='/' className='header-navlink'>
            {working_dep_name}
          </Link>
          {homepage && (
            <svg viewBox='0 0 1024 1024' className='header-icon2' onClick={add_new}>
              <path d='M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z' className=''></path>
            </svg>
          )}
        </div>
      )}
      <div className='header-container'></div>
      <div className='header-container1' onClick={swicthProfile}>
        <svg viewBox='0 0 1024 1024' className='header-icon4'>
          <path d='M316 366l196 196 196-196 60 60-256 256-256-256z' className=''></path>
        </svg>
        <Avatar src={avatar_img} size='34' name={profile_data["name"]} className='header-image' />
      </div>
      {visibility && <Profile setVisibility={swicthProfile} profile_data={profile_data}></Profile>}
      {biz_visibility && <BusinessSelector setVisibility={swicthBizProfile}></BusinessSelector>}
    </div>
  );
};

Header.defaultProps = {
  rootClassName: "",
};

Header.propTypes = {
  rootClassName: PropTypes.string,
};

export default Header;
