import React from "react";
import { useHistory } from "react-router-dom";

import "./business_name_list_item.css";

import storageService from "../../services/storage";

const BusinessNameListItem = (props) => {
  let history = useHistory();

  const switchActtiveBiz = () => {
    storageService.setStorageData("working_dep", props.biz_id);
    storageService.setStorageData("active_department", props.biz_id);
    console.log(props.biz_id);
    history.push("/");
    window.location.reload();
  };

  return (
    <div className='businessname-listitem-businessname-listitem' onClick={switchActtiveBiz}>
      <span className='businessname-listitem-text'>{props.name}</span>
    </div>
  );
};

export default BusinessNameListItem;
