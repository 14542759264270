import React from "react";

import PropTypes from "prop-types";

import "./hist_failed.css";

const Histfailed = (props) => {
  return (
    <div className='histfailed-histfailed'>
      <span className='histfailed-text'>{props.title_text}</span>
      <span className='histfailed-text1'>{props.date_text}</span>
      <span className='histfailed-text2'>{props.amount_text}</span>
      <div className='histfailed-container'>
        <span className='histfailed-text3'>REJECTED</span>
      </div>
    </div>
  );
};

Histfailed.defaultProps = {};

Histfailed.propTypes = {
  date_text: PropTypes.string,
  title_text: PropTypes.string,
  amount_text: PropTypes.string,
};

export default Histfailed;
