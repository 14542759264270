import React from "react";
import { useQuery, useMutation } from "urql";
import uuid from "react-uuid";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./start_selling_widget.css";
import storageService from "../../services/storage";
import { useState } from "react";

let access_point = storageService.getStorageData("access_point");

const startsale_mutation = `
  mutation ($contract_id:ID!,$contractAmount:Float!){
    saveContract(data:[{id:$contract_id, contractType:"SALE", contractAmount:$contractAmount, contractStage:"STAGE001"}]) {
      id
    }
  }
  `;

const StartSellingWidget = (props) => {
  let history = useHistory();

  const [resContr, getContr] = useMutation(startsale_mutation);
  const [amount, setAmount] = useState(null);

  let new_contract_id = uuid();

  const regMutate = (event) => {
    event.preventDefault();
    getContr({
      contract_id: new_contract_id,
      contractAmount: amount * 1,
    }).then((result) => {
      if (result.error) {
        console.error("Oh no!", result.error);
      } else {
        history.push("/stage001/" + new_contract_id);
      }
    });
  };

  return (
    <form className='startsellingwidget-startsellingwidget' onSubmit={regMutate}>
      <span className='startsellingwidget-text'>Start Selling</span>
      <input
        type='number'
        max='100000'
        min='10'
        step='1'
        required
        placeholder='amount in VATU'
        className='startsellingwidget-textinput input'
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value);
        }}
      />
      <button type='submit' className='startsellingwidget-navlink button'>
        -&gt; NEXT -&gt;
      </button>
    </form>
  );
};

export default StartSellingWidget;
