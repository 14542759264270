import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "urql";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import storageService from "../../services/storage";

import Header from "../components/header";
import Managementstrip from "../components/managementstrip";
import Avatarlistitemwithadder from "../components/avatarlistitemwithadder";
import Avatarlistitemwithdelete from "../components/avatarlistitemwithdelete";

import "./team_widget.css";
import { toast } from "react-toastify";

let access_point = storageService.getStorageData("access_point");

const search_profile = `
    query graphSearchProfileWithUser($name:String!) {
      graphSearchProfileWithUser(name:$name) {
        id
        name
        entity {
            id
        }
        avatar {
          id
          path
        }      
      }
    }
  `;

const add_user = `mutation savePosition($pos_id:ID!,$title:String!, $level:String!, $profile_id:ID!, $dep_id:ID! ){
    savePosition(data:[{id:$pos_id, title:$title, level:$level, employee:{id:$profile_id}, department:{id:$dep_id}}]){
        id
    }
    }
  `;

const del_user = `mutation delPosition($pos_id:ID!){
    delPosition(data:[$pos_id]){
        id
    }
    }
  `;

const TeamWidget = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchInputInput, setSearchInputInput] = useState("");

  const [resultadduser, adduser] = useMutation(add_user);
  const [resultdeluser, deluser] = useMutation(del_user);

  const [usersearch, setUserSearch] = useState([]);
  let _usersearch = [];

  let _userexists = [];

  const slength = () => {
    if (searchInput.trim().length < 3) {
      return true;
    } else {
      return false;
    }
  };

  const shouldPause = slength() === true;
  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: search_profile,
    variables: { name: searchInput.trim() },
    requestPolicy: "cache-and-network",
    pause: shouldPause,
  });
  const { data, fetching, error } = result;

  if (error) {
    toast.error("Some error hapened");
  }

  if (props.data) {
    props.data.getDepartment.employee.map((employee) => {
      if (employee.level == "MANAGER" && props.category == "Managers") {
        _userexists.push(
          <Avatarlistitemwithdelete
            key={employee.id}
            rootClassName='avatarlistitemwithdelete-root-class-name1'
            className=''
            name={employee.employee.name}
            avatar={employee.employee.avatar}
            reexecute={reexecuteQuery}
            pos_id={employee.id}
            mutation={deluser}
            category={props.category}></Avatarlistitemwithdelete>
        );
      }
      if (employee.level == "TEAM" && props.category == "Team") {
        _userexists.push(
          <Avatarlistitemwithdelete
            key={employee.id}
            rootClassName='avatarlistitemwithdelete-root-class-name1'
            className=''
            name={employee.employee.name}
            avatar={employee.employee.avatar}
            reexecute={reexecuteQuery}
            pos_id={employee.id}
            mutation={deluser}
            category={props.category}></Avatarlistitemwithdelete>
        );
      }
    });
  }

  useEffect(() => {
    if (searchInput.trim().length > 2) {
      reexecuteQuery();
    }

    _usersearch = [];

    if (error) {
      _usersearch.push();
    }

    if (fetching) {
      _usersearch.push();
    }

    try {
      data.graphSearchProfileWithUser.map((emp) => {
        _usersearch.push(
          <Avatarlistitemwithadder
            rootClassName='avatarlistitemwithadder-root-class-name3'
            name={emp.name}
            avatar={emp.avatar}
            key={emp.id}
            profile_id={emp.id}
            mutation={adduser}
            reexecute={reexecuteQuery}
            category={props.category}></Avatarlistitemwithadder>
        );
      });
    } catch {}

    setUserSearch(_usersearch);
  }, [searchInput]);

  return (
    <div className='team-team'>
      <div className='team-container1'>
        <svg viewBox='0 0 1024 1024' className='team-icon'>
          <path
            d='M896 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM725.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z'
            className=''></path>
        </svg>
        <span className='team-text'>{props.category}</span>
      </div>
      <input
        type='text'
        id='search team'
        name='search team'
        placeholder='search'
        className='team-textinput input'
        value={searchInputInput}
        onChange={(e) => {
          setSearchInput(e.target.value.trim());
          setSearchInputInput(e.target.value);
        }}
      />
      <div className='team-searchbox'>{usersearch}</div>
      <div className='team-managerlist'>{_userexists}</div>
    </div>
  );
};

export default TeamWidget;
