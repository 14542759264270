import React, { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

import PropTypes from "prop-types";

import "./profile.css";

const Profile = (props) => {
  async function swicthProfile() {
    props.setVisibility();
  }

  let avatar_img = "";
  try {
    if (props.profile_data["avatar"]["path"] == "") {
    } else {
      avatar_img = process.env.REACT_APP_STORAGE_READ_HOST + "/" + props.profile_data["avatar"]["path"];
    }
  } catch {}

  return (
    <div className={`profile-container`}>
      <nav className='profile-nav'>
        <svg viewBox='0 0 1024 1024' className='profile-closeicon' onClick={swicthProfile}>
          <path d='M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z' className=''></path>
        </svg>
        <Avatar src={avatar_img} size='110' name={props.profile_data["name"]} className='profile-avatar' />
        <span id='username' className='profile-username'>
          {props.profile_data["name"]}
        </span>
        <div className='profile-divider'></div>

        <div className='profile-extendercontainer'></div>
        <div className='profile-divider02'></div>
        <Link to='/login' className='profile-navlink1'>
          <div className='profile-logoutcontainer1'>
            <span className='profile-logouttext1'>Logout</span>
            <svg viewBox='0 0 1024 1024' className='profile-logouticon'>
              <path
                d='M170 214v596h342v86h-342q-34 0-59-26t-25-60v-596q0-34 25-60t59-26h342v86h-342zM726 298l212 214-212 214-60-62 110-110h-434v-84h434l-110-112z'
                className=''></path>
            </svg>
          </div>
        </Link>
      </nav>
    </div>
  );
};

Profile.defaultProps = {
  rootClassName: "",
};

Profile.propTypes = {
  rootClassName: PropTypes.string,
};

export default Profile;
