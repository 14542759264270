import React from "react";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import Successmessage from "../components/successmessage";
import "./success.css";

const Success = (props) => {
  return (
    <div className='success-container'>
      <Helmet>
        <title>Vanuatu post - Registar</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name26'></Header>
      <div className='success-contentframe'>
        <div className='success-contentcontainer'>
          <div className='success-homepanel'>
            <Successmessage rootClassName='successmessage-root-class-name'></Successmessage>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
