import React, { useEffect, useState } from "react";
import storageService from "../../services/storage";
import { useQuery } from "urql";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import ManagementStrip from "../components/managementstrip";
import "./business.css";
import MainScreenWidget from "../components/mainscreen_widget";
import BusinessSettings from "../components/business_settings";

let access_point = storageService.getStorageData("access_point");

const Business = (props) => {
  let working_dep = null;

  working_dep = storageService.getStorageData("working_dep");

  let tmp_f = [];
  if (working_dep) {
    tmp_f = <MainScreenWidget></MainScreenWidget>;
  } else {
    tmp_f = <BusinessSettings data={null}></BusinessSettings>;
  }

  return (
    <div className='business-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Business - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name'></Header>
      <div className='business-contentframe'>
        <div className='business-contentcontainer'>
          {working_dep && <ManagementStrip></ManagementStrip>}
          <div className='business-homepanel'>{tmp_f}</div>
        </div>
      </div>
    </div>
  );
};

export default Business;
