import React, { useState } from "react";

import { Helmet } from "react-helmet";
import QrReader from "modern-react-qr-reader";
import { useQuery, useMutation } from "urql";
import storageService from "../../services/storage";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/header";
import Managementstrip from "../components/managementstrip";
import "./scanforbalance.css";

import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

let password = process.env.REACT_APP_CY;
let salt = process.env.REACT_APP_SALTIE;

let iterations = 128;
let bytes = CryptoJS.PBKDF2(password, salt, { keySize: 48, iterations: iterations });
let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

const saleStage001 = `mutation getDIDBalanceStore($idNo:String!){
    getDIDBalanceStore(idNo:$idNo){
        balance
    }
    }
  `;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Scanforbalance = (props) => {
  let history = useHistory();

  let contract_id = props.match.params.contract_id;

  const [fixeropen, setfixeropen] = useState(false);

  const [resultValue, setResultValue] = useState(null);

  const [cameradirection, setcameradirection] = useState("environment");

  const [legacy, setlegacy] = useState(false);

  const [manualidno, setmanualidno] = useState("");

  const [camallow, setCamallow] = useState(true);
  const previewStyle = {
    facingMode: cameradirection,
  };

  const [stageResult, stageSubmit] = useMutation(saleStage001);

  const handleError = (err) => {
    //console.error(err);
    toast.error("Readout Error..");
  };

  const handleScan = (data) => {
    //console.error(data);
    if (data == null) {
    } else {
      if (data.startsWith("VGID")) {
        let ciphertext_idNo = CryptoJS.AES.encrypt(data.toString().split("\\")[1].split("/")[0], key, { iv: iv });

        setCamallow(false);
        stageSubmit({
          idNo: ciphertext_idNo.toString(),
        }).then((result) => {
          if (result.error) {
            console.error("Oh no!", result.error);
            toast.error("Some error happened with our servers..");
            setCamallow(true);
          } else {
            setResultValue(result.data.getDIDBalanceStore.balance);
          }
        });
      }
    }
  };

  const switchfixer = () => {
    if (fixeropen == true) {
      setfixeropen(false);
    } else {
      setfixeropen(true);
    }
  };

  const switchcamera = () => {
    if (cameradirection == "environment") {
      setcameradirection("user");
    } else {
      setcameradirection("environment");
    }
  };

  const switchlegacy = () => {
    if (legacy == true) {
      setlegacy(false);
    } else {
      setlegacy(true);
    }
  };

  const try_manual = (event) => {
    event.preventDefault();

    if (manualidno.length > 2) {
      let ciphertext_idNo = CryptoJS.AES.encrypt(manualidno, key, { iv: iv });

      setCamallow(false);

      stageSubmit({
        idNo: ciphertext_idNo.toString(),
      }).then((result) => {
        console.log(result);
        if (result.error) {
          console.error("Oh no!", result.error);
          toast.error("Some error happened with our servers..");
          setCamallow(true);
        } else {
          setResultValue(result.data.getDIDBalanceStore.balance);
        }
      });
    }
  };

  return (
    <div className='scanforbalance-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

      <Helmet>
        <title>Business - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name27'></Header>
      <div className='scanforbalance-contentframe'>
        <div className='scanforbalance-contentcontainer'>
          <Managementstrip></Managementstrip>
          <div className='scanforbalance-homepanel'>
            {resultValue == null ? (
              <div className='scanforbalance-homepanel'>
                {camallow && (
                  <QrReader
                    delay={500}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                    constraints={{ facingMode: cameradirection }}
                    facingMode={cameradirection}
                    className='s1-container'
                  />
                )}

                <span className='scanforbalance-text'>Scan a Vanuatu National ID Code to check Balance</span>

                <div className='registar-camerafixer'>
                  <div className='registar-headlinecamerafixer' onClick={switchfixer}>
                    <span className='registar-camerafixertext'>Fix Camera issues</span>
                    {fixeropen == false && (
                      <svg viewBox='0 0 1024 1024' className='registar-openicon'>
                        <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
                      </svg>
                    )}
                    {fixeropen == true && (
                      <svg viewBox='0 0 1024 1024' className='registar-closeicon'>
                        <path d='M316 658l-60-60 256-256 256 256-60 60-196-196z'></path>
                      </svg>
                    )}
                  </div>
                  {fixeropen == true && (
                    <div className='registar-fixcameracollapsible'>
                      <div className='registar-fixcamerarefreshcontainer'>
                        <span className='registar-fixcamerarefreshtext'>1, Refresh the page -&gt;</span>
                        <button
                          className='registar-refreshfixcamera button'
                          onClick={() => {
                            window.location.reload();
                          }}>
                          REFRESH
                        </button>
                      </div>
                      <div className='registar-fixcameraswitchcontainer'>
                        <span className='registar-fixcameraswitchtext'>2, Try to Switch the Camera Direction -&gt;</span>
                        <button className='registar-switchfixedcamera button' onClick={switchcamera}>
                          SWITCH
                        </button>
                      </div>
                      <div className='registar-fixcameraentercontainer'>
                        <span className='registar-fixcameraentertext'>3, Enter the ID Number manually</span>
                        <form className='registar-fixcameraenterform'>
                          <input
                            type='number'
                            step='1'
                            min='100'
                            placeholder='National ID no.'
                            className='registar-fixcameraenterinput input'
                            value={manualidno}
                            onChange={(e) => {
                              setmanualidno(e.target.value);
                            }}
                          />
                          <svg viewBox='0 0 1024 1024' className='registar-sendfixcamera' onClick={try_manual}>
                            <path d='M362 214l214 298-214 298h-192l214-298-214-298h192zM662 214l212 298-212 298h-192l212-298-212-298h192z'></path>
                          </svg>
                        </form>
                      </div>
                      <div className='registar-fixcameraexplanationcontainer'>
                        <span className='registar-fixcameraexplanationtext'>
                          If your camera is permanently not switching on or blocked, you may forget to authorize Camera Access when you
                          first used the application. Please go to your Browsers SETTINGS -&gt; Site Settings -&gt; Camera to authorize it,
                          than refresh the page.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className='scanforbalance-balanceamount'>
                <span className='scanforbalance-text1'>
                  <span>Available Balance:</span>
                  <br></br>
                </span>
                <span className='scanforbalance-text4'>
                  <span>{numberWithCommas(resultValue) + " VT"}</span>
                  <br></br>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scanforbalance;
