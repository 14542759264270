import React, { useState } from "react";
import { useQuery, useMutation } from "urql";
import { useHistory } from "react-router-dom";

import "./business_settings.css";
import storageService from "../../services/storage";
import uuid from "react-uuid";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let access_point = storageService.getStorageData("access_point");

const islaQuery = `query allIsland{
    allIsland(pageLength:100){
        id
        name
    }
    }
  `;

const bizRegMutation = `mutation vanuatuPostBusinessRegistration($business_id:ID!, $business_name:String!, $business_tin:String!, $business_type:String!, $business_island:ID!, $business_location:String!, $bank_id:ID!, $bank_name:String!, $bank_branch:String!, $bank_holder:String!, $bank_no:String!, $bank_sortcode:String!){
    vanuatuPostBusinessRegistration(departmentDetails:{ id:$business_id, name:$business_name, public:false,tinNo:$business_tin, businessType:$business_type, island:{id:$business_island}, location:$business_location }, bankDetails:{id:$bank_id, bankName:$bank_name, bankBranch:$bank_branch, accountHolderName:$bank_holder, accountNo:$bank_no, sortCode:$bank_sortcode}){
        message
        myDepartments
    }
    }
  `;

const BusinessSettings = (props) => {
  let history = useHistory();

  let _business_id = uuid();
  let _business_name = "";
  let _business_tin = "";
  let _business_type = "";
  let _business_island = "";
  let _business_location = "";

  let _business_bank_id = uuid();
  let _business_bank_name = "NBV";
  let _business_bank_branch = "";
  let _business_bank_account_holder = "";
  let _business_bank_account_no = "";
  let _business_bank_sort_code = "";

  const [resultRegi, regBiz] = useMutation(bizRegMutation);

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: islaQuery,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  let tmp_sorted_islands = [];
  let efate_id = "";
  let sorted_islands = [];

  if (data) {
    data.allIsland.map((island) => {
      tmp_sorted_islands.push(island.name);
    });

    tmp_sorted_islands = tmp_sorted_islands.sort();

    tmp_sorted_islands.map((island) => {
      let tmp_i_s = {};
      data.allIsland.map((isl) => {
        if (island == isl.name) {
          tmp_i_s = { id: isl.id, name: isl.name };
        }
        if (efate_id == "") {
          if (isl.name == "Efate") {
            efate_id = isl.id;
          }
        }
      });
      sorted_islands.push(tmp_i_s);
    });
  }

  _business_island = efate_id;

  if (props.data != null) {
    _business_id = props.data.id;
    _business_name = props.data.name;
    _business_tin = props.data.tinNo;
    try {
      _business_island = props.data.island.id;
    } catch {}
    _business_type = props.data.businessType;
    _business_location = props.data.location;
    try {
      _business_bank_id = props.data.bankDetails[0].id;
      _business_bank_name = props.data.bankDetails[0].bankName;
      _business_bank_branch = props.data.bankDetails[0].bankBranch;
      _business_bank_account_holder = props.data.bankDetails[0].accountHolderName;
      _business_bank_account_no = props.data.bankDetails[0].accountNo;
      _business_bank_sort_code = props.data.bankDetails[0].sortCode;
    } catch {}
  }

  const [business_name, set_business_name] = useState(_business_name);
  const [business_tin, set_business_tin] = useState(_business_tin);
  const [business_type, set_business_type] = useState(_business_type);
  const [business_island, set_business_island] = useState(_business_island);
  const [business_location, set_business_location] = useState(_business_location);
  const [business_bank_name, set_business_bank_name] = useState(_business_bank_name);
  const [business_bank_branch, set_business_bank_branch] = useState(_business_bank_branch);
  const [business_bank_account_holder, set_business_bank_account_holder] = useState(_business_bank_account_holder);
  const [business_bank_account_no, set_business_bank_account_no] = useState(_business_bank_account_no);
  const [business_bank_sort_code, set_business_bank_sort_code] = useState(_business_bank_sort_code);

  const regMutate = (event) => {
    event.preventDefault();
    regBiz({
      business_id: _business_id.trim(),
      business_name: business_name.trim(),
      business_tin: business_tin.trim(),
      business_type: business_type.trim(),
      business_location: business_location.trim(),
      business_island: business_island.trim(),
      bank_id: _business_bank_id.trim(),
      bank_name: business_bank_name.trim(),
      bank_branch: business_bank_branch.trim(),
      bank_holder: business_bank_account_holder.trim(),
      bank_no: business_bank_account_no.trim(),
      bank_sortcode: business_bank_sort_code.trim(),
    }).then((result) => {
      if (result.error) {
        console.error("Oh no!", result.error);
        toast.error("Some mistake happened..");
      } else {
        try {
          //props.reexecute();

          storageService.setStorageData("app_deps", JSON.stringify(result["data"]["vanuatuPostBusinessRegistration"]["myDepartments"]));
          try {
            storageService.setStorageData("working_dep", result["data"]["vanuatuPostBusinessRegistration"]["myDepartments"][0]["id"]);
            storageService.setStorageData("active_department", result["data"]["vanuatuPostBusinessRegistration"]["myDepartments"][0]["id"]);
          } catch {}

          history.push("/");
        } catch {
          toast.error("Some mistake happened..");
        }
      }
    });
  };

  return (
    <div className='businesssettings-businesssettings'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <form className='businesssettings-form' onSubmit={regMutate}>
        <div className='businesssettings-container'>
          <svg viewBox='0 0 1024 1024' className='businesssettings-icon'>
            <path d='M469.333 128h-298.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-298.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v298.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h298.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM759.168 76.501l-405.333 405.333c-5.205 5.163-9.259 11.947-11.221 19.84l-42.667 170.667c-1.664 6.4-1.792 13.568 0 20.693 5.717 22.869 28.885 36.779 51.755 31.061l170.667-42.667c7.125-1.749 14.080-5.504 19.84-11.221l405.333-405.333c25.984-25.984 38.997-60.16 38.997-94.165s-13.013-68.181-38.997-94.165-60.203-39.040-94.208-39.040-68.181 13.013-94.165 38.997zM819.499 136.832c9.344-9.344 21.504-13.995 33.835-13.995s24.491 4.651 33.835 13.995 13.995 21.504 13.995 33.835-4.651 24.491-13.995 33.835l-396.971 396.971-90.197 22.571 22.571-90.197z'></path>
          </svg>
          <span className='businesssettings-text'>Business Details</span>
        </div>
        <input
          type='text'
          id='bname'
          name='bname'
          required
          placeholder='business name'
          minLength={4}
          pattern="^(?!_$)(?!['-._])(?!.*[_.-]{2})[a-zA-Z0-9 '_.-]+(?<!['.-])$"
          className='businesssettings-textinput input'
          value={business_name}
          onChange={(e) => {
            set_business_name(e.target.value);
          }}
        />
        <input
          type='text'
          id='tin'
          name='tin'
          required
          placeholder='TIN Number'
          minLength={4}
          className='businesssettings-textinput1 input'
          value={business_tin}
          onChange={(e) => {
            set_business_tin(e.target.value);
          }}
        />
        <select
          className='businesssettings-select'
          value={business_type}
          onChange={(e) => {
            set_business_type(e.target.value);
          }}>
          <option value='GENERICISLAND'>Generic Island Store</option>
          <option value='SUPMARKET'>Supermarket</option>
          <option value='CONVENIENCE'>Convenience Store</option>
          <option value='HARDWARE'>Hardware Store</option>
          <option value='PETROLSTATION'>Petrol Station</option>
          <option value='PHARMACY'>Pharmacy</option>
          <option value='CLOTHS'>Cloths Store</option>
          <option value='BOTTLE'>Bottle Shop</option>
          <option value='TRANSPORT'>Transportation</option>
        </select>

        <select
          className='businesssettings-select1'
          value={business_island}
          onChange={(e) => {
            set_business_island(e.target.value);
          }}>
          {sorted_islands.map((island) => {
            return (
              <option value={island.id} key={island.id}>
                {island.name}
              </option>
            );
          })}
        </select>

        <textarea
          placeholder='describe where you located'
          className='businesssettings-textarea textarea'
          value={business_location}
          onChange={(e) => {
            set_business_location(e.target.value);
          }}></textarea>
        <div className='businesssettings-container1'>
          <svg viewBox='0 0 1024 1024' className='businesssettings-icon2'>
            <path d='M469.333 469.333h-64c-29.483 0-56.064-11.904-75.435-31.232s-31.232-45.952-31.232-75.435 11.904-56.064 31.232-75.435 45.952-31.232 75.435-31.232h64zM554.667 554.667h64c29.483 0 56.064 11.904 75.435 31.232s31.232 45.952 31.232 75.435-11.904 56.064-31.232 75.435-45.952 31.232-75.435 31.232h-64zM725.333 170.667h-170.667v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v128h-64c-52.992 0-101.077 21.547-135.765 56.235s-56.235 82.773-56.235 135.765 21.547 101.077 56.235 135.765 82.773 56.235 135.765 56.235h64v213.333h-213.333c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h213.333v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-128h64c52.992 0 101.077-21.547 135.765-56.235s56.235-82.773 56.235-135.765-21.547-101.077-56.235-135.765-82.773-56.235-135.765-56.235h-64v-213.333h170.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z'></path>
          </svg>
          <span className='businesssettings-text1'>Bank Details</span>
        </div>
        <select
          required
          className='businesssettings-select2'
          value={business_bank_name}
          onChange={(e) => {
            set_business_bank_name(e.target.value);
          }}>
          <option value='NBV'>NBV</option>
          <option value='BRED'>BRED</option>
          <option value='BSP'>BSP</option>
          <option value='ANZ'>ANZ</option>
          <option value='WANFUTENG'>WANFUTENG</option>
        </select>
        <input
          type='text'
          id='bankbr'
          name='banbr'
          required
          placeholder='bank branch'
          className='businesssettings-textinput2 input'
          value={business_bank_branch}
          onChange={(e) => {
            set_business_bank_branch(e.target.value);
          }}
        />
        <input
          type='text'
          id='ahn'
          name='ahn'
          required
          placeholder='account holder name'
          className='businesssettings-textinput3 input'
          value={business_bank_account_holder}
          onChange={(e) => {
            set_business_bank_account_holder(e.target.value);
          }}
        />
        <input
          type='text'
          id='acno'
          name='acno'
          required
          placeholder='account number'
          className='businesssettings-textinput4 input'
          value={business_bank_account_no}
          onChange={(e) => {
            set_business_bank_account_no(e.target.value);
          }}
        />
        <input
          type='text'
          id='sortcode'
          name='sortcode'
          placeholder='sort code if apply'
          className='businesssettings-textinput5 input'
          value={business_bank_sort_code}
          onChange={(e) => {
            set_business_bank_sort_code(e.target.value);
          }}
        />
        <div className='businesssettings-container2'>
          <button type='submit' className='businesssettings-button button'>
            SAVE
          </button>
        </div>
      </form>
    </div>
  );
};

export default BusinessSettings;
