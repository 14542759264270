import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './successmessage.css'

const Successmessage = (props) => {
  return (
    <div className={`successmessage-errormessage ${props.rootClassName} `}>
      <svg viewBox="0 0 1024 1024" className="successmessage-icon">
        <path
          d="M954.857 323.429c0 14.286-5.714 28.571-16 38.857l-491.429 491.429c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-284.571-284.571c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168.571 374.857-375.429c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857z"
          className=""
        ></path>
      </svg>
      <span className="successmessage-text">Purchase SUCCESSFUL</span>
      <div className="successmessage-container"></div>
      <Link to="/" className="successmessage-navlink button">
        FINISH
      </Link>
    </div>
  )
}

Successmessage.defaultProps = {
  rootClassName: '',
}

Successmessage.propTypes = {
  rootClassName: PropTypes.string,
}

export default Successmessage
