import React, { useEffect, useState } from "react";
import storageService from "../../services/storage";
import { useQuery } from "urql";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/header";
import ManagementStrip from "../components/managementstrip";
import BusinessSettings from "../components/business_settings";
import "./settings.css";

let access_point = storageService.getStorageData("access_point");

const dep_details = `
    query getDepartment($id:ID!) {
      getDepartment(id:$id) {
        id   
        name
        tinNo
        businessType
        location
        island {
          id
          name
        }
        bankDetails{
          id
          bankName
          bankBranch
          accountHolderName
          accountNo
          sortCode
        }
      }
    }
  `;

const NewDepartment = (props) => {
  let tmp_f = [];
  tmp_f = <BusinessSettings data={null}></BusinessSettings>;

  return (
    <div className='settings-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Settings - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Business' />
      </Helmet>
      <Header rootClassName='header-root-class-name18'></Header>
      <div className='settings-contentframe'>
        <div className='settings-contentcontainer'>
          <div className='settings-homepanel'>{tmp_f}</div>
        </div>
      </div>
    </div>
  );
};

export default NewDepartment;
