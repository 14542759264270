import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Camera } from "react-camera-pro";
import { useMutation, useQuery } from "urql";
import uuid from "react-uuid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Fab from "@mui/material/Fab";
import { Helmet } from "react-helmet";
import storageService from "../../services/storage";
import { dataURItoBlob } from "../../services/utilities";
import { srcToWebP, blobToWebP, arrayBufferToWebP } from "webp-converter-browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/header";
import Managementstrip from "../components/managementstrip";
import "./step010.css";

let access_point = storageService.getStorageData("access_point");
let db_name = "";
try {
  db_name = storageService.getStorageData("access_point").split("/")[2].split(".")[0];
} catch {}
const saleStage010 = `mutation saleStage010($imageId:ID!, $contractId:String!, $mimeType:String!, $path:String!){
    saveAdminFile(data:[{id:$imageId, mimeType:$mimeType, path:$path}]) {
      id
    }
    saleStage010(imageId:$imageId, contractId:$contractId){
        message
        uid
    }
    }
  `;

const contractCheck = `query getContract($id:ID!){
    getContract(id:$id){
      id
      contractStage  
    }
    }
  `;

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const Step010 = (props) => {
  let history = useHistory();

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [showprogress, setShowProgress] = useState(false);

  let contract_id = props.match.params.contract_id;

  const [stageResult, stageSubmit] = useMutation(saleStage010);

  const [fabon, setfabon] = useState(true);
  const [fixeropen, setfixeropen] = useState(false);
  const [cameradirection, setcameradirection] = useState("environment");

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: contractCheck,
    requestPolicy: "network-only",
    variables: { id: contract_id },
  });

  const { data, fetching, error } = result;

  if (error) {
    toast.error("Some error happened..");
  }

  if (data) {
    if (data.getContract) {
      if (data.getContract.contractStage == "STAGE010") {
      } else {
        if (result.data.getContract.contractStage == "STAGE001") {
          history.push("/stage001/" + contract_id);
        } else {
          if (result.data.getContract.contractStage == "STAGE020") {
            history.push("/stage020/" + contract_id);
          } else {
            if (result.data.getContract.contractStage == "STAGE030") {
              history.push("/stage030/" + contract_id);
            } else {
              history.push("/");
            }
          }
        }
      }
    }
  }

  const switchfixer = () => {
    if (fixeropen == true) {
      setfixeropen(false);
      setfabon(true);
    } else {
      setfixeropen(true);
      setfabon(false);
    }
  };

  const switchcamera = () => {
    if (cameradirection == "environment") {
      setcameradirection("user");
      camera.current.switchCamera();
    } else {
      setcameradirection("environment");
      camera.current.switchCamera();
    }
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setfixeropen(false);
      setImage(imageDataUrl);
    }
  };

  return (
    <div className='step010-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Business - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name23'></Header>
      <div className='step010-contentframe'>
        <div className='step010-contentcontainer'>
          <Managementstrip></Managementstrip>
          <div className='step010-homepanel'>
            <form className='kycedititem-page05' id='pp_form'>
              <span className='kycedititem-text18'>Take a profile picture of the Client</span>
              {image ? (
                <div style={{ width: "100%", height: "auto" }}>
                  <img src={image} className='uploadid-uploadid' style={{ width: "100%", height: "auto" }} />
                  {showprogress && (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  )}
                </div>
              ) : (
                <Camera ref={camera} facingMode={cameradirection} aspectRatio={1 / 1} className='uploadid-uploadid' />
              )}

              <div className='registar-camerafixer'>
                <div className='registar-headlinecamerafixer' onClick={switchfixer}>
                  <span className='registar-camerafixertext'>Fix Camera issues</span>
                  {fixeropen == false && (
                    <svg viewBox='0 0 1024 1024' className='registar-openicon'>
                      <path d='M366 708l196-196-196-196 60-60 256 256-256 256z'></path>
                    </svg>
                  )}
                  {fixeropen == true && (
                    <svg viewBox='0 0 1024 1024' className='registar-closeicon'>
                      <path d='M316 658l-60-60 256-256 256 256-60 60-196-196z'></path>
                    </svg>
                  )}
                </div>
                {fixeropen == true && (
                  <div className='registar-fixcameracollapsible'>
                    <div className='registar-fixcamerarefreshcontainer'>
                      <span className='registar-fixcamerarefreshtext'>1, Refresh the page -&gt;</span>
                      <button
                        className='registar-refreshfixcamera button'
                        onClick={() => {
                          window.location.reload();
                        }}>
                        REFRESH
                      </button>
                    </div>
                    <div className='registar-fixcameraswitchcontainer'>
                      <span className='registar-fixcameraswitchtext'>2, Try to Switch the Camera Direction -&gt;</span>
                      <button className='registar-switchfixedcamera button' onClick={switchcamera}>
                        SWITCH
                      </button>
                    </div>

                    <div className='registar-fixcameraexplanationcontainer'>
                      <span className='registar-fixcameraexplanationtext'>
                        If your camera is permanently not switching on or blocked, you may forget to authorize Camera Access when you first
                        used the application. Please go to your Browsers SETTINGS -&gt; Site Settings -&gt; Camera to authorize it, than
                        refresh the page.
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {image ? (
                <div className='kycedititem-container25'>
                  <button
                    type='button'
                    className='kycedititem-button15 button'
                    onClick={() => {
                      if (showprogress == false) {
                        setImage(null);
                      }
                    }}>
                    New Picture
                  </button>

                  <button
                    type='button'
                    className='kycedititem-button16 button'
                    onClick={async () => {
                      if (showprogress == false) {
                        var cf = dataURItoBlob(image);

                        setShowProgress(true);

                        const webpBlob = await blobToWebP(cf);

                        const options = {
                          method: "PUT",
                          headers: {
                            AccessKey: process.env.REACT_APP_STORAGE_ACCESSKEY,
                            "content-type": "application/octet-stream",
                          },
                          body: webpBlob,
                        };

                        const img_uid = uuid();
                        const pathprepare = "/" + db_name + "/Sale/" + contract_id + "/" + img_uid;

                        const uriimg = process.env.REACT_APP_STORAGE_BASE_HOST + pathprepare + ".webp";

                        fetch(uriimg, options)
                          .then((response) => response.json())
                          .then((response) => {
                            stageSubmit({
                              imageId: img_uid,
                              mimeType: "image/webp",
                              path: pathprepare + ".webp",
                              contractId: contract_id,
                            }).then((result) => {
                              setShowProgress(false);

                              if (result) {
                                if (result.data.saleStage010 != null) {
                                  if (result.data.saleStage010.message == "VALID") {
                                    history.push("/stage020/" + contract_id);
                                  } else {
                                    toast.error("ID does not seem to work..");
                                  }
                                } else {
                                  toast.error("Some server issues ? ..");
                                }
                              }
                            });
                          })
                          .catch((err) => {
                            toast.error("Image upload error");
                          });
                      }
                    }}>
                    Submit Picture
                  </button>
                </div>
              ) : (
                <>
                  {fabon == true && (
                    <Fab
                      color='primary'
                      aria-label='camera'
                      size='large'
                      style={{ position: "fixed", bottom: "50px" }}
                      onClick={() => {
                        let current_photo = camera.current.takePhoto();
                        //console.log("yes");
                        setImage(current_photo);
                      }}>
                      <svg viewBox='0 0 1024 1024' className='floatinccameraicon'>
                        <path d='M1024 810.667v-469.333c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-147.84l-72.661-109.013c-7.765-11.52-20.736-18.987-35.499-18.987h-256c-13.909 0.085-27.307 6.741-35.499 18.987l-72.661 109.013h-147.84c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v469.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h768c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496zM938.667 810.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-768c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-469.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c14.763 0 27.733-7.467 35.499-18.987l72.661-109.013h210.347l72.661 109.013c8.192 12.245 21.589 18.901 35.499 18.987h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165zM725.333 554.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 554.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z'></path>
                      </svg>
                    </Fab>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step010;
