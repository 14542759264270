import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { useMutation, useQuery } from "urql";
import storageService from "../../services/storage";

import { Helmet } from "react-helmet";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/header";
import Managementstrip from "../components/managementstrip";
import "./step020.css";

let access_point = storageService.getStorageData("access_point");

const saleStage020 = `mutation saleStage020($categories:[ID!]!, $contractId:String!){

    saleStage020(categories:$categories, contractId:$contractId){
        message
        uid
    }
    }
  `;

const contractCheck = `query getContract($id:ID!){
    getContract(id:$id){
      id
      contractStage  
    }
    }
  `;

const Step020 = (props) => {
  let history = useHistory();

  let contract_id = props.match.params.contract_id;

  const [stageResult, stageSubmit] = useMutation(saleStage020);

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: contractCheck,
    requestPolicy: "network-only",
    variables: { id: contract_id },
  });

  const { data, fetching, error } = result;

  if (data) {
    if (data.getContract) {
      if (data.getContract.contractStage == "STAGE020") {
      } else {
        if (result.data.getContract.contractStage == "STAGE001") {
          history.push("/stage001/" + contract_id);
        } else {
          if (result.data.getContract.contractStage == "STAGE010") {
            history.push("/stage010/" + contract_id);
          } else {
            if (result.data.getContract.contractStage == "STAGE030") {
              history.push("/stage030/" + contract_id);
            } else {
              history.push("/");
            }
          }
        }
      }
    }
  }

  const [ef, setEF] = useState(false);
  const swap_ef = () => {
    if (ef == false) {
      setEF(true);
    } else {
      setEF(false);
    }
  };

  const [nef, setNEF] = useState(false);
  const swap_nef = () => {
    if (nef == false) {
      setNEF(true);
    } else {
      setNEF(false);
    }
  };

  const [at, setAT] = useState(false);
  const swap_at = () => {
    if (at == false) {
      setAT(true);
    } else {
      setAT(false);
    }
  };

  const [to, setTO] = useState(false);
  const swap_to = () => {
    if (to == false) {
      setTO(true);
    } else {
      setTO(false);
    }
  };

  const [med, setMED] = useState(false);
  const swap_med = () => {
    if (med == false) {
      setMED(true);
    } else {
      setMED(false);
    }
  };

  const [pet, setPET] = useState(false);
  const swap_pet = () => {
    if (pet == false) {
      setPET(true);
    } else {
      setPET(false);
    }
  };

  const [clo, setCLO] = useState(false);
  const swap_clo = () => {
    if (clo == false) {
      setCLO(true);
    } else {
      setCLO(false);
    }
  };

  const [tls, setTLS] = useState(false);
  const swap_tls = () => {
    if (tls == false) {
      setTLS(true);
    } else {
      setTLS(false);
    }
  };

  const [cons, setCONS] = useState(false);
  const swap_cons = () => {
    if (cons == false) {
      setCONS(true);
    } else {
      setCONS(false);
    }
  };

  const [trans, setTrans] = useState(false);
  const swap_trans = () => {
    if (trans == false) {
      setTrans(true);
    } else {
      setTrans(false);
    }
  };

  const handleSubmit = () => {
    let _tmp_array = [];

    if (ef == true) {
      _tmp_array.push("saleTag_ESSENTIAL_FOOD");
    }
    if (nef == true) {
      _tmp_array.push("saleTag_NON_ESSENTIAL_FOOD");
    }
    if (at == true) {
      _tmp_array.push("saleTag_ALCOHOL_TABACO");
    }
    if (to == true) {
      _tmp_array.push("saleTag_TOILETRIES");
    }
    if (med == true) {
      _tmp_array.push("saleTag_MEDICINES");
    }
    if (pet == true) {
      _tmp_array.push("saleTag_PETROL");
    }
    if (clo == true) {
      _tmp_array.push("saleTag_CLOTHING");
    }
    if (tls == true) {
      _tmp_array.push("saleTag_TOOLS");
    }
    if (cons == true) {
      _tmp_array.push("saleTag_CONSTRUCTION_MATERIAL");
    }
    if (trans == true) {
      _tmp_array.push("saleTag_TRANSPORTATION");
    }

    stageSubmit({
      contractId: contract_id,
      categories: _tmp_array,
    }).then((result) => {
      if (result.error) {
        console.error("Oh no!", result.error);
        toast.error("Some error happened during submission..");
      } else {
        if (result.data.saleStage020.message == "VALID") {
          history.push("/stage010/" + contract_id);
        }
        if (result.data.saleStage020.message == "STAGE") {
          if (result.data.saleStage020.uid == "STAGE001") {
            history.push("/stage001/" + contract_id);
          }
          if (result.data.saleStage020.uid == "STAGE010") {
            history.push("/stage010/" + contract_id);
          }
          if (result.data.saleStage020.uid == "STAGE020") {
            history.push("/stage020/" + contract_id);
          }
          if (result.data.saleStage020.uid == "STAGE030") {
            history.push("/stage030/" + contract_id);
          }
        } else {
        }
      }
    });
  };

  return (
    <div className='step020-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Business - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name24'></Header>
      <div className='step020-contentframe'>
        <div className='step020-contentcontainer'>
          <Managementstrip></Managementstrip>
          <div className='step020-homepanel'>
            <span className='step020-text'>
              Please select what type of products the customer is purchasing. Can select MULTIPLE if needed
            </span>
            <div className='step020-container01'>
              <div className='step020-container02' onClick={swap_ef}>
                <span className='step020-text01'>Essential Food</span>
                {ef && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container03' onClick={swap_nef}>
                <span className='step020-text02'>NON-Essential Food</span>
                {nef && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon02'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container04' onClick={swap_at}>
                <span className='step020-text03'>ALCOHOL / TABACCO</span>
                {at && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon04'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container05' onClick={swap_to}>
                <span className='step020-text04'>TOILETRIES</span>
                {to && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon06'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container06' onClick={swap_med}>
                <span className='step020-text05'>MEDICINES</span>
                {med && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon08'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container07' onClick={swap_pet}>
                <span className='step020-text06'>PETROL</span>
                {pet && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon10'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container08' onClick={swap_clo}>
                <span className='step020-text07'>CLOTHING</span>
                {clo && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon12'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container09' onClick={swap_tls}>
                <span className='step020-text08'>TOOLS</span>
                {tls && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon14'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>
              <div className='step020-container10' onClick={swap_cons}>
                <span className='step020-text09'>CONSTRUCTION MATERIAL</span>
                {cons && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon16'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>

              <div className='step020-container09' onClick={swap_trans}>
                <span className='step020-text08'>TRANSPORTATION</span>
                {trans && (
                  <svg viewBox='0 0 1024 1024' className='step020-icon14'>
                    <path d='M384 690l452-452 60 60-512 512-238-238 60-60z'></path>
                  </svg>
                )}
              </div>

              <div className='step020-container11'>
                <button type='button' className='step020-navlink button' onClick={handleSubmit}>
                  -&gt; NEXT -&gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step020;
