import React from "react";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import Managementstrip from "../components/managementstrip";
import "./info.css";

const Info = (props) => {
  return (
    <div className='info-container'>
      <Helmet>
        <title>Team - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Business' />
      </Helmet>
      <Header rootClassName='header-root-class-name21'></Header>
      <div className='info-contentframe'>
        <div className='info-contentcontainer'>
          <Managementstrip rootClassName='managementstrip-root-class-name3'></Managementstrip>
          <div className='info-homepanel'>
            <form className='info-info'>
              <div className='info-container1'>
                <span className='info-text'>
                  <span>Any questions of if you require any assistance, please contact Vanuatu Post Customer Service Department at</span>
                  <br></br>
                  <br></br>
                  <span>info@vanuatupost.vu</span>
                  <br></br>
                  <br></br>
                  <span>55-66-777</span>
                  <br></br>
                  <br></br>
                  <span>We WILL come back to you shortly.</span>
                  <br></br>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
