import React from "react";

import PropTypes from "prop-types";

import "./hist_success.css";

const Histsuccess = (props) => {
  return (
    <div className='histsuccess-histsuccess'>
      <span className='histsuccess-text'>{props.title_text}</span>
      <span className='histsuccess-text1'>{props.date_text}</span>
      <span className='histsuccess-text2'>{props.amount_text}</span>
      <div className='histsuccess-container'>
        <span className='histsuccess-text3'>SUCCESSFUL</span>
      </div>
    </div>
  );
};

Histsuccess.defaultProps = {};

Histsuccess.propTypes = {
  date_text: PropTypes.string,
  amount_text: PropTypes.string,
  title_text: PropTypes.string,
};

export default Histsuccess;
