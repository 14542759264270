import React from "react";

import PropTypes from "prop-types";

import "./hist_cancelled.css";

const Histcancelled = (props) => {
  return (
    <div className='histcancelled-histcancelled'>
      <span className='histcancelled-text'>{props.title_text}</span>
      <span className='histcancelled-text1'>{props.date_text}</span>
      <span className='histcancelled-text2'>{props.amount_text}</span>
      <div className='histcancelled-container'>
        <span className='histcancelled-text3'>CANCELLED</span>
      </div>
    </div>
  );
};

Histcancelled.defaultProps = {};

Histcancelled.propTypes = {
  title_text: PropTypes.string,
  amount_text: PropTypes.string,
  date_text: PropTypes.string,
};

export default Histcancelled;
