import React, { useEffect, useState } from "react";
import storageService from "../../services/storage";
import { useQuery } from "urql";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Header from "../components/header";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Managementstrip from "../components/managementstrip";
import TeamWidget from "../components/team_widget";
import "./team_editor.css";

let access_point = storageService.getStorageData("access_point");

const dep_details = `
    query getDepartment($id:ID!) {
      getDepartment(id:$id) {
        id   
        employee {
            id
            level
            employee{
                id
                name
                avatar {
                    id
                    path
                }
            }
        }
      }
    }
  `;

const TeamEditor = (props) => {
  const dep_uid = storageService.getStorageData("working_dep");

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: dep_details,
    variables: { id: dep_uid },
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  if (error) {
    toast.error("Some error happened..");
  }

  return (
    <div className='teameditor-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Team - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Business' />
      </Helmet>
      <Header rootClassName='header-root-class-name19'></Header>
      <div className='teameditor-contentframe'>
        <div className='teameditor-contentcontainer'>
          <Managementstrip rootClassName='managementstrip-root-class-name1'></Managementstrip>
          <div className='teameditor-homepanel'>
            <TeamWidget id='manager' category='Managers' key='managers' data={data}></TeamWidget>
            <TeamWidget id='team' category='Team' key='team' data={data}></TeamWidget>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamEditor;
