import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./errormessage.css";

const Errormessage = (props) => {
  return (
    <div className='errormessage-errormessage'>
      <svg viewBox='0 0 1024 1024' className='errormessage-icon'>
        <path d='M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z'></path>
        <path d='M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z'></path>
      </svg>
      <span className='errormessage-text'>
        Something is not alright.. Can be the Mistake in the PIN code or the Client is not registered, or run out of balance, or the account
        got blocked. If you think it is happening by mistake please contact Vanuatu Post Office
      </span>
      <div className='errormessage-container'></div>
      <Link to='/'>
        <button className='errormessage-button button'>Close</button>
      </Link>
    </div>
  );
};

export default Errormessage;
