import { getItem, setItem } from "react-safe-storage";

const salt = process.env.REACT_APP_SALT;

const getStorageData = (item_uid) => {
  const dta = getItem(salt, item_uid);
  if (dta) {
    return dta;
  } else {
    return null;
  }
};
const setStorageData = (item_uid, data) => {
  setItem(salt, item_uid, data);
};

const removeStorageData = (item_uid) => {
  localStorage.removeItem(item_uid);
};

const storageService = {
  removeStorageData,
  setStorageData,
  getStorageData,
};
export default storageService;
