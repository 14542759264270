import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { useMutation, useQuery } from "urql";
import storageService from "../../services/storage";

import StartSellingWidget from "./start_selling_widget";
import "./mainscreen_widget.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Histsuccess from "./hist_success";
import Histfailed from "./hist_failed";
import Histcancelled from "./hist_cancelled";

let access_point = storageService.getStorageData("access_point");

const contractCheck = `query getLatestContracts{
    getLatestContracts{
      id
      contractStage  
      contractAmount
      _Created__
      client {
        id
        client {
          id
          name
          
        }
      }
    }
    }
  `;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const MainScreenWidget = (props) => {
  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: contractCheck,
    requestPolicy: "cache-and-network",
  });

  const { data, fetching, error } = result;

  if (error) {
    toast.error("Some error happened..");
  }

  let _F = [];

  if (data) {
    if (data.getLatestContracts != null) {
      data.getLatestContracts.map((contract) => {
        if (contract.contractStage == "PAYED") {
          let _d = new Date(contract._Created__);
          _F.push(
            <Histsuccess
              title_text={contract.client.client.name}
              amount_text={numberWithCommas(contract.contractAmount) + " VT"}
              date_text={_d.toISOString().replace("T", " ")}
              key={contract.id}></Histsuccess>
          );
        } else if (contract.contractStage == "REJECTED") {
          let _d = new Date(contract._Created__);
          _F.push(
            <Histfailed
              title_text={contract.client.client.name}
              amount_text={numberWithCommas(contract.contractAmount) + " VT"}
              date_text={_d.toISOString().replace("T", " ")}
              key={contract.id}></Histfailed>
          );
        } else {
          let _d = new Date(contract._Created__);
          _F.push(
            <Histcancelled
              title_text={contract.client.client.name}
              amount_text={numberWithCommas(contract.contractAmount) + " VT"}
              date_text={_d.toISOString().replace("T", " ")}
              key={contract.id}></Histcancelled>
          );
        }
      });
    }
  }

  return (
    <div className='mainscreenwidget-mainscreenwidget'>
      <StartSellingWidget></StartSellingWidget>
      <span className='mainscreenwidget-historytext'>History</span>
      <div className='mainscreenwidget-historiccontainer'>{_F}</div>
    </div>
  );
};

export default MainScreenWidget;
