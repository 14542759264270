import React, { useState } from "react";
import { Link } from "react-router-dom";
import storageService from "../../services/storage";

import PropTypes from "prop-types";

import BusinessNameListItem from "./business_name_list_item";
import "./business_selector.css";

const BusinessSelector = (props) => {
  async function swicthProfile() {
    props.setVisibility();
  }

  let list_of_biz = JSON.parse(storageService.getStorageData("app_deps"));

  return (
    <div className='businessselector-container'>
      <nav className='businessselector-nav'>
        <svg viewBox='0 0 1024 1024' className='businessselector-closeicon' onClick={swicthProfile}>
          <path d='M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z'></path>
        </svg>
        <div className='businessselector-extendercontainer'>
          {list_of_biz.map((biz) => {
            return <BusinessNameListItem key={biz.id} name={biz.name} biz_id={biz.id}></BusinessNameListItem>;
          })}
        </div>
      </nav>
    </div>
  );
};

export default BusinessSelector;
