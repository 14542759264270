import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { useMutation, useQuery } from "urql";
import storageService from "../../services/storage";

import { Helmet } from "react-helmet";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../components/header";
import Managementstrip from "../components/managementstrip";
import "./step030.css";

let access_point = storageService.getStorageData("access_point");

const saleStage030 = `mutation saleStage030($password:String!, $contractId:String!){

    saleStage030(password:$password, contractId:$contractId){
        message
        uid
    }
    }
  `;

const contractCheck = `query getContract($id:ID!){
    getContract(id:$id){
      id
      contractStage  
      contractAmount
    }
    }
  `;

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const Step030 = (props) => {
  let history = useHistory();
  let shown_amount = 0;
  let contract_id = props.match.params.contract_id;

  const [password, setPassword] = useState("");

  const [stageResult, stageSubmit] = useMutation(saleStage030);

  const [result, reexecuteQuery] = useQuery({
    url: access_point,
    query: contractCheck,
    requestPolicy: "network-only",
    variables: { id: contract_id },
  });

  const { data, fetching, error } = result;

  if (data) {
    if (data.getContract) {
      shown_amount = data.getContract.contractAmount;
      if (data.getContract.contractStage == "STAGE030") {
      } else {
        if (result.data.getContract.contractStage == "STAGE001") {
          history.push("/stage001/" + contract_id);
        } else {
          if (result.data.getContract.contractStage == "STAGE010") {
            history.push("/stage010/" + contract_id);
          } else {
            if (result.data.getContract.contractStage == "STAGE020") {
              history.push("/stage020/" + contract_id);
            } else {
              history.push("/");
            }
          }
        }
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    stageSubmit({
      contractId: contract_id,
      password: password,
    }).then((result) => {
      if (result.error) {
        console.error("Oh no!", result.error);
        history.push("/failed");
      } else {
        if (result.data.saleStage030.uid == "REJECTED") {
          history.push("/failed");
        } else if (result.data.saleStage030.message == "VALID") {
          history.push("/success");
        } else if (result.data.saleStage030.message == "STAGE") {
          if (result.data.saleStage030.uid == "STAGE001") {
            history.push("/stage001/" + contract_id);
          } else if (result.data.saleStage030.uid == "STAGE010") {
            history.push("/stage010/" + contract_id);
          } else if (result.data.saleStage030.uid == "STAGE020") {
            history.push("/stage020/" + contract_id);
          } else if (result.data.saleStage030.uid == "STAGE030") {
            history.push("/stage030/" + contract_id);
          }
        } else {
          history.push("/failed");
        }
      }
    });
  };

  return (
    <div className='step030-container'>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <Helmet>
        <title>Business - Vanuatu Post</title>
        <meta property='og:title' content='Vanuatu post - Registar' />
      </Helmet>
      <Header rootClassName='header-root-class-name25'></Header>
      <div className='step030-contentframe'>
        <div className='step030-contentcontainer'>
          <Managementstrip></Managementstrip>
          <div className='step030-homepanel'>
            <span className='step030-text'>
              <span>
                Hand Over the Phone to the CLIENT to enter his or her PIN Code.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>Client is now Paying </span>
            </span>
            <div className='step030-container1'>
              <span className='step030-text5'>{numberWithCommas(shown_amount) + " VT"}</span>
            </div>
            <form className='step030-container2' onSubmit={handleSubmit}>
              <span className='step030-text6'>Your PIN Code</span>
              <input
                type='password'
                required
                placeholder='PIN code'
                className='step030-textinput input'
                minLength='6'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <button type='submit' className='step030-navlink button'>
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step030;
